import React, { useState } from "react";

import { Tooltip, Icon, Button, Modal } from "antd";

import { isMobile } from "react-device-detect";
import "./style.css";

export default function DemoCard(props) {
  let [showing, setShowing] = useState(false);

  const showModal = () => {
    setShowing((showing = true));
  };

  const hideModal = () => {
    setShowing((showing = false));
    var videos = document.querySelectorAll("iframe, video");
    Array.prototype.forEach.call(videos, function(video) {
      if (video.tagName.toLowerCase() === "video") {
        video.pause();
      } else {
        var src = video.src;
        video.src = src;
      }
    });
  };

  console.log(props.mobileVideo);
  const renderButtons = function() {
    if (isMobile) {
      return (
        <Tooltip title="Recording">
          <Button type="link" size={"small"} className="card-button">
            <a href={props.mobileVideo} target="_blank">
              <Icon type="play-square" key="play-square" />
            </a>
          </Button>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Recording">
          <Button
            type="link"
            size={"small"}
            className="card-button"
            onClick={showModal}
          >
            <Icon type="play-square" key="play-square" />
          </Button>
        </Tooltip>
      );
    }
  };

  return (
    <div>
      <div className="card">
        <a href={props.demo} target="_blank">
          <img alt="card_cover" className="card-img" src={props.coverImg} />
        </a>
        <div className="card-description">
          <p className="card-title">
            <b>{props.title}</b>
          </p>

          <Modal
            title={props.title}
            visible={showing}
            onOk={hideModal}
            onCancel={hideModal}
            width={600}
          >
            <iframe
              width="560"
              height="315"
              src={props.recording}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="true"
            ></iframe>
          </Modal>

          <div className="card-actions">
            {props.recording === "null" ? <p></p> : renderButtons()}

            {props.details === "null" ? (
              <p></p>
            ) : (
              <Tooltip title="Details">
                <Button type="link" size={"small"} className="card-button">
                  <a href={props.details} target="_blank">
                    <Icon type="snippets" key="snippets" />
                  </a>
                </Button>
              </Tooltip>
            )}

            <Tooltip title="Launch">
              <Button type="link" size={"small"} className="card-button">
                <a href={props.demo} target="_blank">
                  <Icon type="desktop" key="desktop" />
                </a>
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}
