import React from 'react';
import LandingContainer from "./pages/Landing"


function App() {
  return (
    <div className="App">
      <LandingContainer/>
    </div>
  );
}

export default App;
