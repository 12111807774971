import React, { Component } from "react";

import DemoCard from "../../components/DemoCard";
import AppLayout from "../../containers/Layout";

import "./style.css";

const data = require("../../data/demos.json");

class LandingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <AppLayout>
          <div className="flex-grid">
            {data.map(demo => (
              <div className="col card-item">
                <DemoCard
                  title={demo.title}
                  coverImg={demo.coverImg}
                  details={demo.details}
                  recording={demo.recording}
                  demo={demo.demo}
                  mobileVideo={demo.mobileVideo}
                />
              </div>
            ))}
          </div>
        </AppLayout>
      </div>
    );
  }
}

export default LandingContainer;
