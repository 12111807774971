import React from "react";

import { Layout, Menu, Breadcrumb } from "antd";
import "./style.css";

const { Header, Content, Footer } = Layout;

export default function AppLayout(props) {
  return (
    <div>
      {/* <Layout className="layout"> */}
      <Header>
        <div className="header">
          <Menu theme="dark" mode="horizontal" style={{ lineHeight: "64px" }}>
            <Menu.Item key="1">
              <img className="header-logo" src="./logo-full.png" />
            </Menu.Item>
          </Menu>
        </div>
      </Header>
      <Content>
        <div
          style={{
            background: "#fff",
            padding: 24,
            minHeight: 280,
            margin: "auto"
          }}
        >
          {props.children}
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Threekit x Dreamforce 2019
      </Footer>
      {/* </Layout> */}
    </div>
  );
}
